@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;600;700;800&display=swap");

body {
  background-color: #eee;
  font-family: "Heebo", Arial, sans-serif;
}

:root {
  --main: #070C4E;
  --red: #f1556c;
  --blue: #007bff;
  --indigo: #6610f2;
}

div {
  box-sizing: border-box;
  direction: rtl;
}

.inline-block {
  display: inline-block;
}

.App {
  text-align: center;
  width: 480px;
  margin: 0 auto;
  min-height: 100vh;
  font-size: 8px;
  background-color: white;

  @media all and (max-device-width: 1000px) {
    width: 100%;
    font-size: 10px;
  }


  @media all and (max-device-width: 720px) {
    font-size: 8px;
  }

  @media all and (max-device-width: 640px) {
    font-size: 6.4px;
  }


}





