.main {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background: no-repeat url("../../images/bg.png");
  background-size: 100% auto;

  @media all and (max-device-width: 1000px) {
    font-size: 10px;
  }

  @media all and (max-device-width: 720px) {
    font-size: 8px;
  }

  @media all and (max-device-width: 640px) {
    font-size: 6.4px;
  }

  .wrapper {
    padding: 0px 6.25%;
    //flex-grow: 1;
    //overflow-y: auto;

    .content {
      text-align: right;
      direction: rtl;
      font-family: "Heebo", Arial, sans-serif;

      .hi-user {

        font-weight: 800;
        font-size: 4em;
        background: linear-gradient(90deg, #64DBAE 58.53%, #3566F6 89.49%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 1em;
        margin-top: 2em;
      }

      .react-slidedown.slide {
        transition-duration: 0.2s;
      }

      .saving-details {
        margin-bottom: 1em;
        border-radius: 0.2em;
        transition: height 0.25s linear;
        background-color: #E9EDFF;
        padding: 1.5em 1em;
        font-size: 2.14em;
        display: grid;
        row-gap: 1em;
        column-gap: 0.5em;
        grid-template-columns: 1em auto 5em 1em;

        .title {
          font-size: 1.1em;
          font-weight: 800;
          grid-column-start: 1;
          grid-column-end: 4;
        }

        img.checkmark {
          width: 1em;
          position: relative;
          top: 0.2em;
          margin-left: 0.9em;
          grid-column-start: 1;
        }

        img.info {
          width: 1em;
          position: relative;
          top: 0.2em;
          margin-left: 0.9em;
          grid-column-start: 4;
          cursor: pointer;
        }

        .text {
          font-weight: 400;
          grid-column-start: 2;
        }

        .amount {
          font-weight: 600;
          grid-column-start: 3;
        }
      }


      .texts {
        margin-bottom: 1.5em;
        font-size: 2.4em;
        color: var(--main);
        line-height: 1.5em;

        a {
          color: var(--main);
        }

        .text1 {
          font-weight: 700;
          margin-bottom: 1em;

          .blue {
            color: #3566F6;
          }
        }

        .text2 {
          font-weight: 400;
          margin-bottom: 1em;
        }

        .text3 {
          font-weight: 500;
          margin-bottom: 1em;

          img {
            width: 1em;
            position: relative;
            top: 0.3em;
            margin-right: 0.5em;
          }

          .click-here {
            cursor: pointer;
            font-weight: bold;
          }
        }
      }

      .remark {
        font-size: 1.66em;
        font-weight: 400;
        //padding: 0 1.5em;
        text-align: center;
        width: 70%;
        margin: 0 auto 2em auto;
      }
    }
  }

  .buttons {
    //position: absolute;
    //bottom: 0;
    //left: 0;
    width: 100%;
    padding: 0em 6.25%;
    margin-top: 0em;

    .approve-button {
      border-radius: 0.2em;
      width: 100%;
      padding: 0.65em;
      color: white;
      outline: none;
      border: none;
      font-weight: 500;
      font-size: 2.66em;
      background-color: var(--main);
    }

    .not-interested {
      font-size: 2.4em;
      padding: 1em 0;
      cursor: pointer;

    }
  }


}