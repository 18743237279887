.proceedToSign {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background: no-repeat url("../../images/bg.png");
  background-size: 100% auto;

  @media all and (max-device-width: 1000px) {
    font-size: 10px;
  }

  @media all and (max-device-width: 720px) {
    font-size: 8px;
  }

  @media all and (max-device-width: 640px) {
    font-size: 6.4px;
  }

  .wrapper {
    padding: 0px 6.25%;
    //flex-grow: 1;
    //overflow-y: auto;

    .content {
      text-align: right;
      direction: rtl;
      font-family: "Heebo", Arial, sans-serif;

      .hi-user {

        font-weight: 800;
        font-size: 4em;
        background: linear-gradient(90deg, #64DBAE 58.53%, #3566F6 89.49%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 1em;
        margin-top: 2em;
      }

      .react-slidedown.slide {
        transition-duration: 0.2s;
      }

      .phone-number {
        margin-bottom: 2em;
        border-radius: 0.2em;
        background-color: #E9EDFF;
        padding: 1em 1em;

        .title {
          font-size: 1.1em;
          font-weight: 800;
          grid-column-start: 1;
          grid-column-end: 4;
        }

        img {
          height: 1em;
          float: left;
          position: relative;
          top: 0.25em;
        }
      }


      .texts {
        margin-bottom: 1.5em;
        font-size: 2.4em;
        color: var(--main);
        line-height: 1.5em;

        a {
          color: var(--main);
        }

        .text1 {
          font-weight: 700;
          margin-bottom: 1em;

          .blue {
            color: #3566F6;
          }
        }

        .text2 {
          font-weight: 400;
          margin-bottom: 1em;
        }
      }

      .footer {
        float: left;
        font-size: 2.66em;
        font-weight: 800;
      }
    }
  }

}