.header {
  text-align: left;
  padding-top: 5vh;
  width: 100%;

  @media all and (max-device-width: 1000px) {
    padding-top: 3vh;
  }

  .logo {
    width: 33%;
    display: inline-block;
    //margin-top: 1.3em;
    @media all and (max-device-width: 1000px) {
      width: 35%;
    }
  }

  .phone-link {
    float: right;
    width: 10%;
    position: relative;
    top: -0.4vh;

    img {
      width: 100%;
    }
  }
}