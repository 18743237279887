.identify {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  direction: rtl;
  background: no-repeat url("../../images/bg.png");
  background-size: 100% auto;

  @media all and (max-device-width: 1000px) {
    font-size: 10px;
  }

  @media all and (max-device-width: 720px) {
    font-size: 8px;
  }

  @media all and (max-device-width: 640px) {
    font-size: 6.4px;
  }

  .wrapper {
    padding: 0px 6.25%;
    //flex-grow: 1;
    overflow-y: auto;

    .header {
      text-align: left;
      padding-top: 5vh;
      width: 100%;

      @media all and (max-device-width: 1000px) {
        padding-top: 3vh;
      }

      .logo {
        width: 33%;
        display: inline-block;
        //margin-top: 1.3em;
        @media all and (max-device-width: 1000px) {
          width: 35%;
        }
      }

      .phone-link {
        float: right;
        width: 10%;
        position: relative;
        top: -0.4vh;

        img {
          width: 100%;
        }
      }
    }

    .content {
      text-align: right;
      direction: rtl;
      font-family: "Heebo", Arial, sans-serif;

      .input {
        display: grid;
        grid-template-columns: auto 5em;
        row-gap: 1em;
        column-gap: 1em;
        margin-top: 4.2em;

        input.error::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #f1859c;
          opacity: 1;
        }

        input.error {
          border: 6px solid var(--red);

          @media all and (max-device-width: 1000px) {
            border: 3px solid var(--red);
          }
        }

        input {
          grid-column-start: 1;
          border-radius: 0.2em;
          outline: none;
          padding: 0em 4%;
          width: 100%;
          box-sizing: border-box;
          height: 2.6em;
          font-size: 2.6em;
        }

        .button-wrapper {
          height: 2.6em;
          font-size: 2.6em;
          padding: 30%;
          box-sizing: border-box;
          background-color: var(--main);
          border-radius: 0.2em;
          img {
            grid-column-start: 2;
            cursor: pointer;
            height: 100%;
            width: 100%;

          }
        }


        div.error {
          grid-column-start: 1;
          grid-column-end: 2;
          width: 100%;
          //marign-top: 0.8em;
          color: var(--red);
          font-size: 2.6em;
        }
      }



      .hi-user {

        font-weight: 800;
        font-size: 4em;
        background: linear-gradient(90deg, #64DBAE 58.53%, #3566F6 89.49%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 1em;
        margin-top: 2em;
      }

      .texts {
        font-size: 2.4em;
        color: var(--main);
        line-height: 1.5em;

        a {
          color: var(--main);
        }

        .text1 {
          font-weight: 700;
          margin-bottom: 1em;

          .blue {
            color: #3566F6;
          }
        }
      }
    }
  }

  .buttons {
    width: 100%;
    padding: 0em 6.25%;
    margin: 3.2em 0;

    .approve-button {
      border-radius: 0.2em;
      width: 100%;
      padding: 0.65em;
      color: white;
      outline: none;
      border: none;
      font-weight: 500;
      font-size: 2.66em;
      background-color: var(--main);
      cursor: pointer;
    }

  }


}