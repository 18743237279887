
.full-screen {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10vh 6.25% 5vh 6.25%;
  z-index: 2;

  .wrapper {
    max-width: 640px;
    margin: 0 auto;
    padding: 5vh 2.5%;
    background-color: white;
    position: relative;
    @media all and (max-device-width: 1000px) {
      padding: 5vh 7% 3vh 7%;
    }

    .title {
      font-weight: 700;
      font-size: 2.66em;
      background: linear-gradient(90deg, #3566F6 0%, #64DBAE 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 1em;

    }

    .close {
      cursor: pointer;
      position: absolute;
      top: 2em;
      right: 2em;
      height: 2em;
    }

    .content {
      text-align: right;
      direction: rtl;
      font-family: "Heebo", Arial, sans-serif;
      font-size: 2.4em;
      color: var(--main);
      line-height: 1.5em;


      .text2 {
        font-weight: 400;
        margin-bottom: 1em;
      }

      .text3 {
        font-weight: 500;
        margin-bottom: 1em;

        img {
          width: 1em;
          position: relative;
          top: 0.3em;
          margin-right: 0.5em;
        }
      }
    }


    .buttons {
      width: 100%;
      padding: 3em 0 1em 0;
      display: flex;

      .approve-button {
        flex-grow: 1;
        border-radius: 0.2em;
        width: 100%;
        padding: 0.25em;
        color: white;
        outline: none;
        font-weight: 500;
        font-size: 2.66em;
        background-color: var(--main);
        border: 0.2em solid var(--main);
      }

      .approve-button.second {
        color: var(--main);
        background-color: white;
      }

      .gap {
        width: 6em;
      }

      .not-interested {
        font-size: 2.4em;
        padding: 1em 0;

        a {
          color: var(--main);
        }
      }
    }
  }


}